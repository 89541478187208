import React from "react"
import FotoRecupera from "@components/pageCasosdeExito/image/imgPhotoEmpresa/recupera/FotoRecupera.png"
import FotoRecupera2 from "@components/pageCasosdeExito/image/imgPhotoEmpresa/recupera/FotoRecupera2.png"

//logo de empresas clientes
import Recupera from "@components/pageCasosdeExito/image/logoscustomer/Recupera.svg"

//iconos de modulos
import llamadas from "@components/pageCasosdeExito/image/iconcards/llamadas.svg"
import crm from "@components/pageCasosdeExito/image/iconcards/crm.svg"
import blending from "@components/pageCasosdeExito/image/iconcards/blending.svg"
import integraciones from "@components/pageCasosdeExito/image/iconcards/integraciones.svg"

//iconos metricas
import metrica1 from "@components/pageCasosdeExito/image/imgMetric/recupera/img1.svg"
import metrica2 from "@components/pageCasosdeExito/image/imgMetric/recupera/img2.svg"
import metrica3 from "@components/pageCasosdeExito/image/imgMetric/recupera/img3.svg"

const text1 = (
  <span className="">
    Grupo Recupera tiene más de 20 años de experiencia en la gestión de cobros y
    recobros para los sectores financieros, comercial, retail, educación y
    servicios. Se apoya en Beex para administrar su operación de Contact Center
    de más de 200 operadores.
    <br /> <br />
    Gracias a sus marcadores inteligentes, como predictivo, progresivo e IVR,
    se ha logrado aumentar su contactabilidad en +28% y su tasa de ocupación
    en +75%. Permitiendo contactar de forma inteligente a sus clientes para
    aumentar su tasa de recupero.
  </span>
)

const text2 = (
  <span>
    Grupo Recupera es el grupo líder en la industria de cobranzas en Perú,
    especializado en brindar servicios de tercerización, análisis y compra de
    cartera, siendo especialistas en diferentes tramos, como carteras
    preventivas, moras tempranas, castigadas y judiciales.
    <br />
    <br />
    A lo largo de todo este tiempo, Grupo Recupera y sus unidades de negocio han
    logrado conformar su Contact Center con más de dos centenares de operadores,
    ampliando sus operaciones hasta Norteamerica en el 2021.
    <br />
    <br />
    Brindando servicios de cobro y recobros a las entidades financieras más
    importantes del país, Grupo Recupera ha logrado conectar de manera
    inteligente y, sobre todo, eficiente, con sus clientes, lo que le ha
    permitido estar por encima del índice de contactabilidad acostumbrado en el
    mercado nacional.
    <br />
    <br />
    Marco Salinas, Gerente Comercial, ha seguido de cerca las mejoras obtenidas
    en su operación y las nuevas implementaciones que se han realizado de Beex
    para las diferentes campañas.
  </span>
)


const text3 = (
  <p className="text3-casos" style={{ position: "relative" }}>
    <span style={{ zIndex: "1", position: "sticky" }}>
      {" "}
      “El marcador Predictivo de Beex{" "}
    </span>
    <span
      style={{
        color: "transparent",
        display: "inline",
        left: "0px",
        position: "absolute",
        top: "-2px",
      }}
    >
      {" "}
      “El marcador Predictivo de{" "}
      <span
        style={{
          boxShadow: " 0 -14px 0 #f7d731 inset",
        }}
      >
        Beex
      </span>
    </span>
    nos ha permitido aumentar en +28% nuestra contactabilidad, que es de los
    puntos más importantes en la industria de las cobranzas. La efectividad con
    la que detecta la voz humana es lo mejor que hemos probado hasta ahora.”
  </p>
)

const text4 = (
  <span>
    Desde el inicio, hace más de 4 años, el objetivo principal de Beex en
    Recupera fue aumentar el nivel de contactabildiad con sus clientes, y se
    logró. Esto llevó a que los índices de recupero aumentaran.
    <br />
    <br />
    La coordinación entre los equipos de TI y operaciones, llevaron a
    implementar el marcador predictivo con una eficiencia de +94% en
    reconocimiento de voz, permitiendo solo el contacto con clientes que
    realmente contestaron. Además, la efectividad en el marcado hizo que las
    diferentes campañas avancen de forma inteligente el barrido de sus bases.
    <br />
    <br />
    A la par, con el objetivo principal logrado, se buscó mejorar incluso más
    estos índices ayudados de la optimización de los tiempos de la operación.
    Esto llevó a evaluar los tiempos de eventos y estados de los operadores para
    mejorarlos, de forma que ayuden en el funcionamiento integral de la
    plataforma.
    <br />
    <br />
    Con esto claro, se mejoraron los tiempos de espera, además de configurar los
    temporizadores de Beex para el ACW. Ahora, Recupera, según sus tramos de
    deuda o tipos campaña, puede cambiar en tiempo real estos temporizadores.
    Todo ello ha llevado a mejorar el índice de adherencia y la tasa de
    ocupación de la operación.
  </span>
)

const text5 = (
  <span>
    “Hemos mejorado considerablemente los tiempos de trabajo de nuestra
    operación. Nuestro porcentaje de Adherencia ha aumentado hasta un 90% y la
    tasa de ocupación subió hasta +75%.”
  </span>
)
const text6 = (
  <p style={{ marginBottom: "40px" }}>
    Además, en un mercado tan competitivo, el tener los datos de todas sus
    campañas en tiempo real ha sido un punto vital para tomar acciones cuando
    algún índice no cumple con los objetivos asociados.
  </p>
)

const text7 = (
  <span>
    “Con Beex, tengo a la mano toda la información de nuestra operación en
    línea, tanto del comportamiento y eventos de nuestros teleoperadores, como
    de los resultados de nuestras campañas”
  </span>
)
const text8 = (
  <p>
    Con la llegada de la pandemia en 2020 y la disposición del gobierno por
    bajar totalmente los aforos de los centros de trabajo, Recupera coordinó con
    Beex Service Desk la migración de Beex, que en ese momento se
    encontraba en la versión On-Premise.
    <br />
    <br />
    La migración se hizo en 4 días. En este tiempo, Grupo Recupera contaba con
    toda su plataforma en la nube para gestionar, donde más de 200
    teleoperadores se conectaron simultáneamente, dándole una continuidad
    realmente efectiva a la operación.
    <br />
    <br />
    Actualmente, siguen con esta versión de Beex a pesar de haber regresado a
    oficinas, y es que les brindan continuidad 24x7, además de escalabilidad en
    tiempo real, pudiendo agregar o disminuir usuarios cuando lo necesiten.
    <br />
    <br />
    Estos son algunos de los procesos con los que ayudamos a Grupo Recupera en su operación de cobranzas:
  </p>
)
const text9 = (
  <p>
    Beex viene trabajando con Recupera más de 4 años, compartiendo feedback
    importante y ayudando a su operación al cumplimiento de metas, en un giro
    donde la potencia en marcación telefónica es vital para lograr sus
    objetivos.
  </p>
)

const data = {
  intro: {
    image: Recupera,
    link: "https://www.youtube.com/watch?v=Qbz9dN0Ap4c",
    text1: text1,
    classlogo: "logo__clientes--kontigo",
    text2: text2,
    text3: text3,
    fotoone: FotoRecupera,
    text4: text4,
    text5: text5,
    text6: text6,
    text7: text7,
    fototwo: FotoRecupera2,
    title8: "Migración a la nube en tiempo record",
    text8: text8,
    text9: text9,
    
    metric1: metrica1,
    metric2: metrica2,
    metric3: metrica3,
  },

  cards: [
    {
      title: "Marcaciones telefónicas",
      img: llamadas,
      info:
        "Marcaciones progresivas y predictivas para llegar a mas clientes en sus mejores horarios, con esto, aumentan la contactabilidad y las chances de cerrar acuerdos de pago.",
    },
    {
      title: "CRM",
      img: crm,
      info:
        "Administración total de carteras y campañas de diferentes tramos, desde preventivas hasta castigadas. Cuentan con todo el detalle de sus clientes que pueden ser multicarteras y sus productos.",
    },
    {
      title: "Blending",
      img: blending,
      info:
        "Gesitón de llamadas inbound y outbound para sus campañas, reduciendo los tiempos muertos de su operación y optimizando la gestión diaria de sus teleoperadores gracias a nuestra función blending.",
    },
    {
      title: "Integraciones",
      img: integraciones,
      info:
        "Implementación de procesos automáticos, como reportes, envíos de información a clientes, data lakes compartidos, integración mediante APIs y más.",
    },
  ],
}

export default data
