/* eslint-disable no-undef */
import React from "react"
import StructurePageRecupera from "@components/pageCasosdeExito/template/templatePageRecupera"
import { Helmet } from "react-helmet"

const IndexPage = ({ location }) => (
  <div className="fnd">
     <Helmet htmlAttributes={{ lang: 'es' }}>
      <title>Cliente de Beex: Grupo Recupera [Antes Securitec]</title>
      <link
        rel="icon"
        type="image/png"
        href={`https://beexcc.com/Beex.png`}
      />
      <link
        rel="canonical"
        href={`https://beexcc.com/casos-de-exito/grupo-recupera/`}
      />
      <meta name="robots" content="noindex, follow"></meta>
      <meta charSet="utf-8" />
      <meta name="description" content="Recupera, importante grupo de cobranzas de Perú ha mejorado su tasa de contacto y adherencia con Beex, plataforma de Contact Center en la nube." />
      <meta property="og:locale" content="es_ES" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Cliente de Beex: Grupo Recupera" />
      <meta
        property="og:url"
        content={`https://beexcc.com/casos-de-exito/grupo-recupera/`}
      />
      <meta
        property="og:image"
        content={`https://beexcc.com/metaimagenRecursos/CasosExito/og-recupera.jpg`}
      />
      <meta
        property="og:description"
        content="Recupera, importante grupo de cobranzas de Perú ha mejorado su tasa de contacto y adherencia con Beex, plataforma de Contact Center en la nube."
      />
    </Helmet>
    <StructurePageRecupera location={location} />
  </div>
)

export default IndexPage
